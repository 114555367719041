<template>
  <WeAppContainer header="" support="" footer="">
    <div class="vertical-center w-65 container-fluid">
      <div class="col-12 p-0">
        <div class="card rounded-0 border-0 shadow">
          <div class="row m-0 align-items-stretch h-100">
            <!-- Slide Section -->
            <div
              class="col-12 col-lg-6 p-0 d-none d-md-flex position-relative"
              style="z-index: 1030"
            >
              <!-- Swiper -->
              <swiper
                :auto-update="true"
                :auto-destroy="true"
                ref="mySwiper"
                :options="swiperOptions"
              >
                <swiper-slide class="h-100"
                  ><img src="https://picsum.photos/1200/550" alt=""
                /></swiper-slide>
                <swiper-slide class="h-100"
                  ><img src="https://picsum.photos/1210/550" alt=""
                /></swiper-slide>
                <swiper-slide class="h-100"
                  ><img src="https://picsum.photos/1220/550" alt=""
                /></swiper-slide>
                <swiper-slide class="h-100"
                  ><img src="https://picsum.photos/1230/550" alt=""
                /></swiper-slide>
                <div
                  class="swiper-button-prev"
                  slot="button-prev"
                  @click="prev()"
                ></div>
                <div
                  class="swiper-button-next"
                  slot="button-next"
                  @click="next()"
                ></div>
              </swiper>
              <!-- ./Swiper -->
              <div
                class="position-absolute w-100 text-center"
                style="
                  bottom: 10px;
                  z-index: 1;
                  opacity: 0.7;
                  user-select: none;
                "
              >
                <div class="rounded bg-light col-8 col-lg-5 mx-auto">
                  <span class="text-dark font-12"
                    >Görseller rastgele oluşmaktadır.</span
                  >
                </div>
              </div>
            </div>
            <!-- ./Slide Section -->

            <!-- Login Section -->
            <div class="col-12 col-lg-6 d-flex align-self-stretch bg-white">
              <div class="card-body">
                <div class="language-switcher dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    v-bind:title="$t('language')"
                    data-toggle="dropdown"
                  >
                    <lang-flag v-bind:iso="$i18n.locale" />
                    <span class="ml-1 text-dark">{{ $t("info.name") }}</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right lang-dropdown">
                    <a
                      v-bind:class="[
                        'dropdown-item',
                        $i18n.locale == lang.info.iso ? 'active' : '',
                      ]"
                      href="#"
                      v-for="(lang, i) in langs"
                      :key="`lang-${i}`"
                      v-on:click.prevent="onChange(lang.info)"
                    >
                      <lang-flag v-bind:iso="lang.info.iso" />
                      <span class="ml-1">{{ lang.info.name }}</span>
                    </a>
                  </div>
                </div>

                <div class="d-block text-center logo-section text-center">
                  <!-- Logo -->
                  <img src="/img/logo/logo-black.png" class="img-fluid" />
                  <!-- ./Logo -->
                </div>

                <div class="row">
                  <div class="col" id="login-card">
                    <!-- E-Mail Address -->
                    <label for="email" class="text-dark custom-label"
                      >E-Posta Adresi</label
                    >
                    <div class="input-section">
                      <!-- Email Input -->
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="E-Posta Adresi"
                        autocomplete="off"
                        required
                        v-model="form.email"
                        @keyup.enter="doLogin()"
                        autofocus
                        v-bind:class="{ 'border-danger': $v.form.email.$error }"
                      />
                      <!-- ./Email Input -->

                      <i class="far fa-envelope"></i>
                    </div>
                    <!-- ./E-Mail Address -->

                    <!-- Password -->
                    <label for="password" class="text-dark custom-label"
                      >Şifre</label
                    >
                    <div class="input-section">
                      <!-- Password Input -->
                      <input
                        type="password"
                        class="form-control input-password"
                        name="password"
                        id="password"
                        placeholder="Şifre"
                        autocomplete="off"
                        required
                        v-model="form.password"
                        @keyup.enter="doLogin()"
                        v-bind:class="{
                          'border-danger': $v.form.password.$error,
                        }"
                      />
                      <!-- ./Password Input -->

                      <i class="fas fa-lock"></i>
                    </div>
                    <!-- ./Password -->
                    <div class="row align-items-center">
                      <!-- Remember Me -->
                      <div
                        class="
                          col-12
                          mb-4
                          col-md-6
                          mb-lg-0
                          text-center text-md-left
                        "
                      >
                        <div class="custom-control custom-checkbox">
                          <!-- Remember Checkbox -->
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="remember-me"
                          />
                          <!-- ./Remember Checkbox -->

                          <label
                            class="custom-control-label font-14"
                            for="remember-me"
                            >Beni Hatırla</label
                          >
                        </div>
                      </div>
                      <!-- ./Remember Me -->

                      <!-- Forgot Password -->
                      <div
                        class="
                          col-12
                          mb-4
                          col-md-6
                          mb-lg-0
                          text-center text-md-right
                        "
                      >
                        <a
                          href=""
                          v-on:click.prevent="switchPage"
                          class="text-custom-link font-14"
                          >Şifremi Unuttum</a
                        >
                      </div>
                      <!-- ./Forgot Password -->
                    </div>
                    <div class="col-12 text-center">
                      <div class="card-body">
                        <!-- Login Button -->
                        <button
                          v-on:click.prevent="doLogin()"
                          class="btn btn-custom-primary"
                          v-if="!submit"
                        >
                          Giriş Yap
                        </button>
                        <button class="btn btn-custom-primary" v-else>
                          <i class="fas fa-spinner fa-spin"></i>
                        </button>
                        <!-- ./Login Button -->
                      </div>
                    </div>
                  </div>
                  <div class="col" id="forgot-card">
                    <!-- E-Mail Address -->
                    <label for="email" class="text-dark custom-label"
                      >E-Posta Adresi</label
                    >
                    <div class="input-section">
                      <!-- Email Input -->
                      <input
                        type="email"
                        class="form-control"
                        name="forgot-email"
                        id="forgot-email"
                        placeholder="E-Posta Adresi"
                        autocomplete="off"
                        required
                        v-model="reset.email"
                        @keyup.enter="resetPassword()"
                        autofocus
                        v-bind:disabled="sendEmail"
                        v-bind:class="{
                          'border-danger': $v.reset.email.$error,
                        }"
                      />
                      <!-- ./Email Input -->

                      <i class="far fa-envelope"></i>
                    </div>
                    <!-- ./E-Mail Address -->

                    <div class="card mb-3 alert-success" v-if="sendEmail">
                      <div class="card-body">
                        <i class="fas fa-check fa-lg mr-2"></i> Şifre Sıfırlama
                        E-Postası başarıyla gönderildi
                      </div>
                    </div>

                    <div class="col-12 text-center">
                      <!-- Submit -->
                      <button
                        v-on:click.prevent="resetPassword()"
                        class="btn btn-custom-primary"
                        v-if="!sendEmail"
                      >
                        E-Posta Gönder
                      </button>
                      <!-- ./Submit -->

                      <!-- Re-send Email -->
                      <div class="btn-group" v-else>
                        <button
                          type="button"
                          class="btn btn-custom-primary disabled"
                        >
                          Tekrar Gönder
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-custom-primary
                            disabled
                            dropdown-toggle-split
                          "
                        >
                          {{ reset.timer }} sn.
                        </button>
                        <!-- ./Re-send Email -->
                      </div>

                      <div class="d-block mt-3">
                        <a
                          href=""
                          v-on:click.prevent="switchPage"
                          class="text-muted"
                          ><i class="fas fa-arrow-left"></i> Giriş Yap</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./Login Section -->
          </div>
        </div>
      </div>
    </div>
  </WeAppContainer>
</template>

<script>
import LangFlag from "vue-lang-code-flags";
import { messages } from "@/locales";

import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";

import requestLogin from "../../models/request/login";
import { required } from "vuelidate/lib/validators";

import { mapActions } from "vuex";
SwiperCore.use([Navigation, Pagination]);

export default {
  name: "Login",
  components: {
    Swiper,
    SwiperSlide,
    LangFlag,
  },
  mounted() {
    this.langs = messages;
  },
  data() {
    return {
      langs: [],
      submit: false,
      sendEmail: false,
      page: "login",
      form: {
        email: null,
        password: null,
      },
      reset: {
        email: null,
        timer: 0,
      },
      swiperOptions: {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // Some Swiper option/callback...
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
    reset: {
      email: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("session", ["login"]),
    onChange(lang) {
      this.$i18n.locale = lang.iso;
      localStorage.setItem("_lang", lang.iso);
      localStorage.setItem("_lang_code", lang.code);
      const dropdownElement = document.querySelector(".lang-dropdown");
      dropdownElement.classList.remove("show");
    },
    switchPage() {
      if (this.page == "login") {
        this.showLoginPage();
      } else {
        this.showResetPasswordPage();
      }
    },
    showLoginPage() {
      const loginCard = document.getElementById("login-card");
      const forgotCard = document.getElementById("forgot-card");

      this.page = "forgot";
      loginCard.style.display = "none";
      forgotCard.style.display = "block";
    },
    showResetPasswordPage() {
      const loginCard = document.getElementById("login-card");
      const forgotCard = document.getElementById("forgot-card");
      // if (this.form.email) this.reset.email = this.form.email; // Fill email with login credential

      this.page = "login";
      loginCard.style.display = "block";
      forgotCard.style.display = "none";
    },
    resetPassword() {
      this.$v.reset.$touch();
      if (this.$v.reset.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return;
      }

      this.reset.timer = 30;
      this.sendEmail = true;
      this.countDownTimer();
    },
    countDownTimer() {
      if (this.reset.timer > 0) {
        setTimeout(() => {
          this.reset.timer -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.sendEmail = false;
      }
    },
    doLogin() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return;
      }

      let self = this;
      this.submit = true;
      this.login({
        requestLogin: requestLogin.new(self.form.email, self.form.password),
        onSuccess: () => {
          this.submit = false;
          this.$router.push("/");
        },
        onFail: () => {
          this.$swal({
            title: "Giriş Bilgileriniz Geçersiz",
            text: "Lütfen kontrol ederek yeniden deneyin",
            icon: "error",
          });
          this.submit = false;
        },
        onError: (error) => {
          if (error && error.response) {
            this.$swal({
              title: "Beklenmeyen bir hata oluştu",
              text: "Lütfen yeniden deneyin",
              icon: "error",
            });
          } else {
            this.$swal({
              title: "Lütfen internet bağlantınızı kontrol edin",
              icon: "error",
            });
          }
          this.submit = false;
        },
      });
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-body {
  background-color: #f3f6fb !important;
  background-image: url("/img/background/soft.jpg");
}

.card-bg {
  background-repeat: no-repeat;
  background-size: cover;
  height: 505px;
}

/* ----- GLOBAL ----- */
.swiper-slide {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.mb-5rem {
  margin-bottom: 5rem;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.text-custom-link {
  color: #5867dd;
  transition: 0.4s all;
}

.text-custom-link:hover {
  color: #4854b1;
}

.btn-custom-primary {
  color: #fff !important;
  background-color: #5867dd;
  border-color: #5867dd;
  font-weight: 500;
  font-size: 1rem;
  padding: 1rem 1.75rem;
  transition: 0.4s all;
}

.btn-custom-primary:hover {
  background-color: #4854b1;
  box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.5);
}

.bg-custom-primary {
  background-color: #5867dd !important;
}

.text-custom-primary {
  color: #5867dd !important;
}

.w-65 {
  width: 65% !important;
}

.carousel-inner {
  height: 100%;
}

.carousel-indicators li {
  width: 14px !important;
  height: 14px !important;
  background-color: #fff;
  border-radius: 100%;
  opacity: 0.3;
  outline: none !important;
}

.carousel-indicators .active {
  background-color: #5867dd !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5867dd !important;
  background-color: #5867dd !important;
}

label {
  user-select: none;
}

/* ----- ./GLOBAL ----- */

.custom-label {
  font-size: 14px;
  font-weight: 500;
}

/* Input Styles */
.form-control {
  border: 1px solid #c4c4c4a1;
  height: 50px;
  margin-bottom: 1.25rem;
  background-color: rgba(247, 247, 249, 0.7);
  padding-left: 2.7rem;
  padding-right: 1.25rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-control:focus {
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  background-color: #f7f7f9;
  border: 1px solid #5868dd81;
  -webkit-box-shadow: 0px 3px 10px rgba(211, 211, 211, 0.7) !important;
  box-shadow: 0px 3px 10px rgba(211, 211, 211, 0.7) !important;
}

.form-control:focus + i {
  color: #5867dd;
}

.form-control.is-invalid + i {
  color: #e3342f !important;
}
.form-control.is-valid + i {
  color: #38c172 !important;
}

.input-section {
  position: relative;
}

.input-section i {
  transition: all 0.3s ease-out;
  position: absolute;
  left: 15px;
  top: 16px;
  font-size: 17px;
}

/* ./Input Styles */

.swiper-card {
  min-height: 75vh;
}

.logo-section {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
  max-width: 50%;
  margin: auto;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  position: inherit !important;
  width: 100% !important;

  .swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    text-align: center;
    line-height: 20px !important;
    font-size: 12px;
    opacity: 1;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
    margin: 3px;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    color: #ffffff;
    background: #3f51b5;
    transform: scale(1);
  }
}

@media only screen and (max-width: 1198px) {
  .w-65 {
    width: 100% !important;
  }

  .card-bg {
    height: 100%;
  }
}

@media only screen and (min-width: 1198px) {
  body {
    overflow: hidden !important;
  }
}
#login-card {
  position: relative;
}
#forgot-card {
  display: none;
}

.language-switcher {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
